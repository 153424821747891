// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/TextAnimation.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/TextAnimation.tsx");
  import.meta.hot.lastModified = "1715872054673.6953";
}
// REMIX HMR END

import { useEffect, useState } from "react";
const words = ['bedriftslån', 'kassakreditt', 'fakturasalg', 'høyrente konto'];
const initialText = 'bedriftslån';
export default function TextAnimation() {
  _s();
  const [text, setText] = useState(initialText);
  const [animationKey, setAnimationKey] = useState(0);
  useEffect(() => {
    let wordIndex = 0;
    const intervalId = setInterval(() => {
      wordIndex = (wordIndex + 1) % words.length;
      setText(initialText.replace('bedriftslån', words[wordIndex]));
    }, 1500);
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    setAnimationKey(animationKey + 1);
  }, [text]);
  return <div key={animationKey} className="text-6xl font-black text-center text-white py-3 animate-appear-animation-text">{text}</div>;
}
_s(TextAnimation, "8S8+W4i7GZdVZiKipJ/lCFIxVxM=");
_c = TextAnimation;
var _c;
$RefreshReg$(_c, "TextAnimation");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;