// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/TopSection.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/TopSection.tsx");
  import.meta.hot.lastModified = "1715872054674.6953";
}
// REMIX HMR END

import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";
import TopFormContent from "./TopForm";
import TextAnimation from "./TextAnimation";
function TopSection() {
  return <div className="bg-[url('./images/top-section.png')] bg-no-repeat  bg-cover w-full">
      <div className="sm:h-[600px] w-full md:flex justify-center items-center sm:pt-16">
        <div className="max-w-full sm:h-[644px] transparent flex flex-col items-center">
          <div className="sm:p-7  sm:w-1/2 text-center">
            <div className="rounded-xl bg-emerald-900 w-fit m-auto">
              <p className="px-3 text-white text-center">
                Få tilbud fra opptil <span className="text-yellow-500">3 +</span> banker, tjenesten er
                <span className="text-yellow-500"> gratis </span> og <span className="text-yellow-500"> uforpliktende </span>
              </p>
            </div>
            <p className="text-6xl font-black text-center text-white py-3">Få tilbud på</p>
            <TextAnimation />
            <p className="text-6xl font-black text-center text-white py-3">fra flere banker </p>
            <p className="text-2xl text-center text-white font-normal py-3">Fyll ut og la bankene kjempe om deg, vår tjeneste er rask og enkel og gir deg trygghet for at du velger det beste tilbudet. </p>
          </div>

          <Tabs defaultValue="tab1" className="">
            <TabsList className="w-full rounded-t-lg">
              <TabsTrigger value="tab1" className="w-1/3 data-[state=active]:bg-sky-200">Bedriftslån</TabsTrigger>
              <TabsTrigger value="tab2" className="w-1/3 data-[state=active]:bg-sky-200">Kassakreditt</TabsTrigger>
              <TabsTrigger value="tab3" className="w-1/3 data-[state=active]:bg-sky-200">Plasseringskonto</TabsTrigger>
            </TabsList>
            <TabsContent className="mt-0" value="tab1"><TopFormContent value="loans" /></TabsContent>
            <TabsContent className="mt-0" value="tab2"><TopFormContent value="overspending" /></TabsContent>
            <TabsContent className="mt-0" value="tab3"><TopFormContent value="placement" /></TabsContent>
          </Tabs>
        </div>
      </div>

    </div>;
}
_c = TopSection;
export default TopSection;
var _c;
$RefreshReg$(_c, "TopSection");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;